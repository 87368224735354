import React, { useContext } from "react";
import Layout from "../components/Layout";
import { LangStateContext } from "../context/LangContext";
import TitikPintarWebsiteVideo from '../Assets/Videos/Titik-Pintar-Website-Video.mp4';
import Seo from '../components/seo/Seo';
import '../styles/PressPage.scss';

const PressPage = () => {
  const { isIndoSelected } = useContext(LangStateContext);
  console.log(isIndoSelected);

  return (
    <Layout>
        <Seo 
            title='Titik Pintar - Press and Media'
            description='Press Release / Rilis Berita'
            keywords='Press, Release, Berita, Titik Pintar'
            />
      <div id="page" className='press-page'>
            <div className='press-page-text'>
                <div id="primary" class="content-area">
                    <main id="main" class="site-main">
                        <article id="post-410" class="post-410 page type-page status-publish hentry">
                            <header class="entry-header">
                                <h1 class="entry-title">Press / Media</h1>
                                <p>Press Release / Rilis Berita</p>
                            </header>
                            <div class="entry-content">
                                <h2>Logo</h2>
                                <ul>
                                    <li><a href="/press/logo/titik-pintar-logo.pdf">PDF Vector</a></li>
                                    <li><a href="/press/logo/titik-pintar-logo.png">PNG-24</a></li>
                                </ul>
                                <h2>Summary text</h2>
                                <ul>
                                    <li><h3>About Titik Pintar</h3>
                                        <ul>
                                            <li>
                                                <b>Bahasa Indonesia</b>
                                                <p>
                                                    Didukung oleh Pemerintah Belanda dan Indonesia, Titik Pintar adalah startup EdTech Indonesia yang berusaha untuk meningkatkan hasil 
                                                    pembelajaran bagi anak-anak sekolah dasar Indonesia melalui platform daring yang bersifat mendidik sekaligus menghibur. 
                                                    Materi pembelajaran kami disesuaikan untuk setiap anak di sekolah dasar di Indonesia. 
                                                    Kami berkomitmen untuk berkontribusi dalam mencapai <a href="https://www.un.org/sustainabledevelopment/" rel="noreferrer" target="_blank"> Sustainable Development Goals </a> (SDGs) Nomor 4 dan 5 yang ditetapkan oleh PBB, 
                                                    yaitu Pendidikan Berkualitas dan Kesetaraan Gender.
                                                </p>
                                            </li>
                                            <li>
                                                <b>English</b>
                                                <p>
                                                    Supported by the Dutch and Indonesian Government, Titik Pintar is an Indonesian EdTech startup striving to improve learning outcomes 
                                                    for Indonesian elementary school children via online tools that are both educational and entertaining. 
                                                    Our learning materials are tailored for every child in elementary school in Indonesia. 
                                                    We aim to contribute toward achieving The United Nations <a href="https://www.un.org/sustainabledevelopment/" rel="noreferrer" target="_blank"> Sustainable Development Goals </a> (SDGs) number 4 and 5: Quality Education and Gender Equality.
                                                </p>
                                            </li>
                                        </ul>
                                    </li>
                                    <li><h3>About Sahabat Pintar</h3>
                                        <ul>
                                            <li>
                                                <b>Bahasa Indonesia</b>
                                                <p>Sahabat Pintar adalah platform yang diluncurkan oleh Titik Pintar untuk menyediakan konten edukasi gratis bagi para pengajar di seluruh Indonesia. Materi pelajaran di SahabatPintar.id dipantau oleh para guru sekolah dasar yang memiliki pengalaman mengajar selama lebih dari 10 tahun. Hal ini bertujuan untuk menjaga kualitas materi agar sesuai dengan kualitas standar Titik Pintar dan Kurikulum Nasional</p>
                                                <p>Sahabat Pintar juga menjadi sarana kolaborasi antara guru dan desainer untuk membuat konten edukasi yang lebih interaktif dan menarik untuk siswa Sekolah Dasar.</p>
                                                <p>Sahabat Pintar mengadakan kompetisi untuk guru dan desainer, dan memberikan hadiah uang tunai ke pada tim guru dan desainer dengan karya terbaik. Hasil karya terpilih akan diunggah ke aplikasi Titik Pintar agar bisa diakses oleh ribuan pengguna Titik Pintar di seluruh Indonesia.</p>
                                            </li>
                                            <li>
                                                <b>English</b>
                                                <p>Sahabat Pintar is a platform launched by Titik Pintar to provide free educational content for teachers throughout Indonesia. The content at SahabatPintar.id is monitored by elementary school teachers who have over 10 years of teaching experience. This makes sure the quality of the material is in accordance with Titik Pintar’s high quality standards and the National Curriculum (Kurtilas).</p>
                                                <p>Sahabat Pintar also acts as a collaboration platform for teachers and designers to create more interactive and interesting educational content for elementary school students.</p>
                                                <p>Sahabat Pintar holds competitions for teachers and designers and will reward cash prizes to the team with the best work. The selected work will be uploaded to the Titik Pintar application so that it can be accessed by thousands of Titik Pintar users in Indonesia.</p>
                                            </li>
                                        </ul>
                                    </li>




                                </ul>
                                <h2>App preview</h2>
                                <ul>
                                    <li><a href="/press/app-preview/1.png">Illustation 1</a></li>
                                    <li><a href="/press/app-preview/2.png">Illustation 2</a></li>
                                    <li><a href="/press/app-preview/3.png">Illustation 3</a></li>
                                    <li><a href="/press/app-preview/4.png">Illustation 4</a></li>
                                    <li><a href="/press/app-preview/5.png">Illustation 5</a></li>
                                    <li><a href="/press/app-preview/6.png">Illustation 6</a></li>
                                    <li><a href="/press/app-preview/7.png">Illustation 7</a></li>
                                    <li><a href="/press/app-preview/8.jpg">NEW - Illustation 8</a></li>
                                    <li><a href="/press/app-preview/9.png">NEW - Illustation 9</a></li>
                                    <li><a href="/press/app-preview/10.png">NEW - Illustation 10</a></li>
                                    <li><a href="/press/app-preview/11.png">NEW - Illustation 11</a></li>
                                    <li><a href="/press/app-preview/12.png">NEW - Illustation 12</a></li>
                                    <li><a href="/press/app-preview/13.jpg">NEW - Illustation 13</a></li>
                                    <li><a href="/press/app-preview/14.jpg">NEW - Illustation 14</a></li>
                                    <li><a href="/press/app-preview/15.jpg">NEW - Illustation 15</a></li>
                                    <li><a href="/press/app-preview/16.jpg">NEW - Illustation 16</a></li>
                                    <li><a href="/press/app-preview/17.jpg">NEW - Illustation 17</a></li>
                                    <li><a href="/press/app-preview/18.jpg">NEW - Illustation 18</a></li>
                                    <li><a href="/press/app-preview/19.jpg">NEW - Illustation 19</a></li>
                                </ul>
                                <h2>Photo</h2>
                                <ul>
                                    <li><a href="/press/photo/1.jpg">Photo 1</a></li>
                                    <li><a href="/press/photo/2.jpg">Photo 2</a></li>
                                    <li><a href="/press/photo/3.jpg">Photo 3</a></li>
                                    <li><a href="/press/photo/4.jpg">Photo 4</a></li>
                                    <li><a href="/press/photo/5.jpg">Photo 5</a></li>
                                    <li><a href="/press/photo/6.jpg">Photo 6</a></li>
                                    <li><a href="/press/photo/7.jpg">Photo 7</a></li>
                                    <li><a href="/press/photo/8.jpg">NEW - Photo 8</a></li>
                                    <li><a href="/press/photo/9.jpg">NEW - Photo 9</a></li>
                                    <li><a href="/press/photo/10.jpg">NEW - Photo 10</a></li>
                                    <li><a href="/press/photo/11.jpg">NEW - Photo 11</a></li>
                                    <li><a href="/press/photo/12.jpg">NEW - Photo 12 : An Indonesian elementary student is learning school materials while playing on the Titik Pintar app after school with his mother.</a></li>
                                    <li><a href="/press/photo/13.jpg">NEW - Photo 13 : A kid is playing Titik Pintar app at home while learning the subjects he learns from school interactively. </a></li>
                                </ul>

                                <h2>Video</h2>
                                <ul>
                                    <li><a href={TitikPintarWebsiteVideo} download="Website Introduction.mp4">Website introduction</a></li>
                                    <li><a href="https://storage.googleapis.com/titikpintar/press/titik-pintar-ad-30sec.mp4">Banner advertisement 30 sec</a></li>
                                    <li><a href="https://storage.googleapis.com/titikpintar/press/titik-pintar-ad-15sec.mp4">Banner advertisement 15 sec</a></li>
                                </ul>

                                <h2>Press releases</h2>
                                <ul>
                                    <li>
                                        <p>28-09-2021
                                            <ul>
                                                <li><a href="https://storage.googleapis.com/ttp-production-assets/20210928%20-%20Tanggapi%20Studi%20Bank%20Dunia%20tentang%20Kerugian%20Pembelajaran%20yang%20Dialami%20Pelajar%20Indonesia%2C%20Titik%20Pintar%20Tawarkan%20Pembelajaran%20Jarak%20Jauh%20Gratis%20untuk%20Siswa%20SD.pdf">
                                                    Tanggapi Studi Bank Dunia tentang Kerugian Pembelajaran yang Dialami Pelajar Indonesia, Titik Pintar Tawarkan Pembelajaran Jarak Jauh Gratis untuk Siswa SD
                                                </a></li>
                                                <li><a href="https://storage.googleapis.com/ttp-production-assets/20210928%20-%20Responding%20to%20World%20Bank%20Study%20on%20learning%20loss%20in%20Indonesia%2C%20Titik%20Pintar%20Offers%20Free%20Remote%20Learning%20for%20Elementary%20School%20Students.pdf">
                                                    Responding to World Bank Study on learning loss in Indonesia, Titik Pintar Offers Free Remote Learning for Elementary School Students
                                                </a></li>
                                            </ul>
                                        </p>
                                    </li>
                                    <li>
                                        <p>12-01-2021
                                            <ul>
                                                <li><a href="https://storage.googleapis.com/titikpintar/press/2020112%20-%20Titik%20Pintar%20Dapatkan%20Pendanaan%20Kelembagaan%20Pertama%20di%20babak%20Pre-Seed%20-%20Bahasa%20Indonesia.pdf">Titik Pintar Dapatkan Pendanaan Kelembagaan Pertama di babak Pre-Seed</a></li>
                                                <li><a href="https://storage.googleapis.com/titikpintar/press/2020112%20-%20Titik%20Pintar%20Raises%20First%20Institutional%20Capital%20in%20Pre-Seed%20round%20-%20English.pdf">Titik Pintar Raises First Institutional Capital in Pre-Seed round</a></li>
                                            </ul>
                                        </p>
                                    </li>
                                    <li>
                                        <p>25-11-2020
                                            <ul>
                                                <li><a href="https://storage.googleapis.com/titikpintar/press/%5BSiaran%20Pers%5D%20Titik%20Pintar%20Luncurkan%20SahabatPintar.id%20-%20Situs%20Penyedia%20Konten%20Edukasi%20Sekolah%20Dasar%20Berkualitas.pdf">Titik Pintar Luncurkan SahabatPintar.id: Situs Penyedia Konten Edukasi Sekolah Dasar Berkualitas</a></li>
                                                <li><a href="https://storage.googleapis.com/titikpintar/press/%5BPress%20Release%5D%20Titik%20Pintar%20Releases%20SahabatPintar.id-%20A%20Platform%20with%20High-Quality%20Educational%20Content%20for%20Elementary%20School%20Children.pdf">Titik Pintar Releases SahabatPintar.id: A Platform with High-Quality Educational Content for Elementary School Children</a></li>
                                            </ul>
                                        </p>
                                    </li>
                                    <li>
                                        <p>16-03-2020
                                            <ul>
                                                <li><a href="https://storage.googleapis.com/titikpintar/press/Siaran%20Pers%20-%20Titik%20Pintar%20Menawarkan%20Solusi%20Belajar%20Alternatif%20Saat%20COVID-19.pdf">Titik Pintar Menawarkan Solusi Belajar Alternatif Saat COVID-19</a></li>
                                                <li><a href="https://storage.googleapis.com/titikpintar/press/%5BPress%20Release%5D%20Titik%20Titik%20Pintar%20Offers%20an%20Educational%20Alternative%20During%20COVID-19.pdf">Titik Pintar Offers an Educational Alternative During COVID-19</a></li>
                                            </ul>
                                        </p>
                                    </li>



                                </ul>

                                <p>If you have any questions, comments or requests, please address them to:</p>
                                <p>Robbert Deusing, hi@titikpintar.id</p>

                            </div>
                        </article>
                    </main>
                </div>
            </div>
        </div>
    </Layout>
  );
};

export default PressPage;
